<template>
	
	<div
		:id="group_id"
		ref="group"
		class="group"
		:class="{
			screenshot : screenshot,
			nodata : nodata,
		}"
	>

		<slot name="screenshot-content" v-if="screenshot"></slot>

		<container
			ref="group_container"
			:container_id="'deck-recap_datas'"
			class="container-datas"
			:classes="{
				noheader	: screenshot,
				loading	: !loaded,
				load	: load,
			}"
			:collapse_on_mount="false"
			:screenshot_function="take_screenshot"
			:screenshot="screenshot"
			:tweet_content="tweet_content"
		>

			<template slot="container-head">
				<slot name="summary-head"></slot>
			</template>

			<template slot="head-actions" v-if="!load">
				<slot name="summary-head-actions"></slot>
				<!-- <button @click="load_datas()">Charger</button> -->
			</template>

			<template slot="container-body">

				<div class="deck-recap_left">
					
					<div id="deck-recap_global">

						<chart-bar 
							:label="'Deck #' + deck.number"
							:base_width="deck_higher"
							:value="deck.pts_avg"
							:value_text="parseInt( deck.pts_avg ) + ' PTS MOYENNE'"
							:classes="{ total: true, rungood : deck_rungood >= 0, notrungood : deck_rungood < 0 }"
						></chart-bar>

						<chart-bar 
							:label="'Estimation'" 
							:base_width="deck_higher" 
							:value="deck.estim_avg" 
							:value_text="parseInt( deck.estim_avg ) + ' PTS MOY. ESTIMÉS'" 
							:classes="'estimation'" 
						></chart-bar>

						<chart-bar 
							:label="'Deck #' + ( deck.number - 1 )" 
							:base_width="deck_higher" 
							:value="last_deck.pts_avg" 
							:value_text="parseInt( last_deck.pts_avg ) + ' PTS MOYENNE'" 
							:classes="'last-total'" 
						></chart-bar>

					</div>

					<table class="datas-table desktop">
						
						<thead>

							<tr class="thead-main">

								<th class="rank">
									<div>RANK</div>
								</th>
								<th class="rank">
									<div>EVO</div>
								</th>
								<th>
									<div>PSEUDO</div>
								</th>
								<th>
									<div>POINTS (vs ESTIMATION)</div>
								</th>
								<th class="score">
									BP
								</th>

							</tr>

						</thead>

						<tbody><tr 
							v-for="( user, key ) in users" 
							v-bind:key="key" 
							:user="user.id" 
						>

							<td class="rank"><div>
								<div><em>#</em>{{ user.deck_ranking.value }}</div>
							</div></td>

							<td class="rank-evo"><div>
								<div>
									<em class="evo" :class="{ green : user.ranking_evo >= 0, red : user.ranking_evo < 0 }">
										{{ user.ranking_evo }}
									</em>
								</div>
							</div></td>

							<td><div>
								{{ user.pseudo }}
							</div></td>

							<td class="scores"><div>

								<chart-bar 
									:label="'Estimation : ' + user.week_estimation + ' PTS'" 
									:base_width="user.higher" 
									:value="user.week_estimation" 
									:classes="'estimation'" 
								></chart-bar>

								<chart-bar 
									:label="'Score : ' + user.week_score + ' PTS (' + ( ( user.rungood > 0 ) ? '+' + user.rungood : user.rungood ) + ')'" 
									:base_width="user.higher" 
									:value="user.week_score" 
									:classes="{ total : true, rungood : user.rungood >= 0, notrungood : user.rungood < 0 }" 
								></chart-bar>

							</div></td>

							<td class="text-center">
								<span>{{ user.best_picks.total }}</span>&nbsp;
								<span :class="{ green : ( user.best_picks.week > 0 ) }">({{ ( user.best_picks.week > 0 ) ? '+' + user.best_picks.week : 0 }})</span>
							</td>

						</tr></tbody>

					</table>

				</div>

				<div :class="{
					'deck-recap_highlights'	: true,
					'screenshot'	: screenshot,
				}">

					<div class="user" v-if="best_user.pseudo">

						<div class="datas-container">

							<div class="title">
								<img class="emoji" src="@/assets/img/emojis/strong.png" />
								{{ best_user.pseudo }}
							</div>

							<div class="datas">

								<div class="score">
									<span>TOTAL</span>
									<span>{{ best_user.week_score }} PTS</span>
								</div>
								<div class="score">
									<span>MOYENNE</span>
									<span>{{ best_user.week_avg.toFixed( 2 ) }} PTS</span>
								</div>
								<div class="score">
									<span>BEST PICK</span>
									<span :class="score_good_bad( best_user.deck_bp, 40 )">
										{{ best_user.deck_bp }} PTS
									</span>
								</div>

							</div>

						</div>
						
						<div class="img">

							<div class="badge">Player of the week</div>
							<div class="picture"><img :src="require( '@/assets/img/users/' + best_user.pseudo.toLowerCase() + '.jpg' )"></div>

						</div>

					</div>

					<div class="player best-pick" v-if="deck.best_pick && deck.best_pick.player">

						<div class="datas-container">

							<div class="title">
								<img class="emoji" src="@/assets/img/emojis/stars.png" />
								{{ get_mobile_fullname( deck.best_pick.player, 10 ) }}
							</div>

							<div class="datas">

								<div class="score">
									<span>SCORE</span>
									<span>{{ deck.best_pick.score }} PTS</span>
								</div>
								
								<div class="score">
									<span>vs ESTIMATION</span>
									<span :class="score_good_bad( ( deck.best_pick.score - deck.best_pick.estimation ), 0 )">
										{{ score_diff( deck.best_pick.score, deck.best_pick.estimation ) }}
									</span>
								</div>
							
							</div>

						</div>
						
						<div class="img">

							<div class="badge">Pick of the week</div>
							<div class="picture"><img :src="'http://ak-static.cms.nba.com/wp-content/uploads/headshots/nba/latest/260x190/' + deck.best_pick.player_id + '.png'"></div>
						</div>

					</div>

					<div class="player hell-pick" v-if="deck.hell_pick && deck.hell_pick.player">

						<div class="datas-container">

							<div class="title">
								<img class="emoji" src="@/assets/img/emojis/carrot.png" v-if="deck.hell_pick.score < 20" />
								{{ get_mobile_fullname( deck.hell_pick.player, 10 ) }}
							</div>

							<div class="datas">

								<div class="score">
									<span>SCORE</span>
									<span>{{ deck.hell_pick.score }} PTS</span>
								</div>

								<div class="score">
									<span>vs ESTIMATION</span>
									<span :class="score_good_bad( (deck.hell_pick.score - deck.hell_pick.estimation ), 0 )">
										{{ score_diff( deck.hell_pick.score, deck.hell_pick.estimation ) }}
									</span>
								</div>

							</div>

						</div>
						
						<div class="img">
							<div class="badge">Carrot of the week</div>
							<div class="picture nba-player"><img :src="'http://ak-static.cms.nba.com/wp-content/uploads/headshots/nba/latest/260x190/' + deck.hell_pick.player_id + '.png'"></div>
						</div>

					</div>

					<div class="team-ranks">

						<div class="title">Team ranks</div>

						<table>
							<tr 
								v-for="( user, key ) in users_by_rank" 
								v-bind:key="key" 
								:user="user.id" 
							>
								<td>{{ ( key + 1 ) }}</td>
								<td><span>#</span>{{ user.deck_ranking.value }}</td>
								<td>{{ user.pseudo }}</td>
							</tr>
						</table>

					</div>

				</div>
				
			</template>
		
		</container>

	</div>

</template>

<script>

	import mixins_container from '@/mixins/container'
	import mixins_summary from '@/mixins/summary'
	import mixins_tools from '@/mixins/tools'

	export default {

		name	: 'summary-container',

		components	: {

			'container'	: () => import( '@/components/container.vue' ),
			'chart-bar'	: () => import( '@/components/chart-bar.vue' ),

		},

		mixins	: [

			mixins_tools,
			mixins_container,
			mixins_summary,

		],

		props	: [

			'group_id',
			'deck',
			'last_deck',
			'ldeck_end_date',
			'tweet_content',
			
		],

		data	: function() { return {
			
			load	: false,
			nodata	: false,

			date_start	: this.$moment(),
			date_end	: this.$moment(),

			rankings	: [],
			
			// deck	: false,
			// last_deck	: false,

			deck_higher	: 0,
			deck_ranking	: 0,	// Ranking de la team
			deck_rungood	: 0,	// Comparaison estimation vs total points

			// best_user	: false,
			// ldeck_end_date	: false,

			// screenshot	: true,

			evo	: 0,

		}},

		methods	: {
		},

		watch	: {
		},

		computed	: {

			users() {

				return this.sort({
					field	: 'week_score',
					dir	: 'desc',
				})

			},

			users_by_rank() {

				return this.sort({
					field	: 'ranking',
					dir	: 'asc',
				})

			},

		},

		mounted	: function() {
		},

	}

</script>

<style lang="scss" scoped>

	@import '@/assets/scss/_base';
	@import '@/assets/scss/font-avenger';

	@import '@/assets/scss/datas-table';

	$left_width	: 60%;



	/** ---------------------------------------*\
	*---------------------------------------- **/

	.group .container::v-deep {

		.container-head {

			background-color: #FFF;

			.head-main {
				flex: 100%;
			}

			.head-actions.custom-actions button {
				margin-left: 15px;
			}

		}

		&.maximized .container-head {
			border-bottom: 4px solid #000;
		}

		.container-body,
		.deck-recap_left {
			border-top: 0;
		}

		.container-body {
			@include flex_display;
			flex-flow: row nowrap;
			align-items: stretch;
			justify-content: space-between;
		}

		&:not(.load) {

			.container-head {
				border-bottom: none;
			}

			.container-body {
				display: none;
			}

		}

	}

	/** ---------------------------------------*\
	*---------------------------------------- **/

	.group .container::v-deep .container-head {

		.title {
			@include font_avenger;
		}

		& > div.head-actions {
			// padding-right: 0;
			border-left: 2px solid #000;
		}

	}

	/** ---------------------------------------*\
		Bloc droite
		Highlights
	*---------------------------------------- **/

	.group .container::v-deep .deck-recap_highlights {

		flex: calc( 100% - #{$left_width} );
		max-width: calc( 100% - #{$left_width} );
		background: #FFF;
		border-left: 4px solid #000;

		.title {

			margin-bottom: 15px;
			@include font_avenger;
			font-size: 1.2em;
			line-height: 1em;
			color: $green;

		}

		& > div {

			$img-width	: 175px;

			display: flex;
			justify-content: space-between;
			align-items: stretch;
			width: 100%;
			border-bottom: 4px solid #000;
			font-size: 1.3em;

			&:last-child {
				border-bottom: 0;
			}

			div.datas-container {

				flex: calc( 100% - #{$img-width} );
				max-width: calc( 100% - #{$img-width} );
				border-right: 2px solid #000;
				color: #FFF;
				font-weight: bold;
				text-transform: none;

				div.title {

					display: flex;
					align-items: center;
					height: 50px;
					padding: 15px 20px;
					margin-bottom: 0;
					font-size: 1.2em;
					line-height: 1em;

					img {

						display: block;
						width: 25px;
						height: 25px;
						margin-top: -5px;
						margin-right: 15px;
						object-fit: contain;

					}

				}

				.datas {

					display: grid;
					grid-auto-rows: 4;
					height: calc( 100% - 50px );

					& > div {
						padding: 0 15px;
						border-top: 2px solid #000;
					}

				}

				.score {

					display: flex;
					align-items: center;
					justify-content: space-between;
					background: transparent;

					.bad {
						color: $red;
					}

					.good {
						color: $green;
					}

				}

				em {
					display: inline-block;
					margin: 0 5px;
					font-style: normal;
				}

			}

			div.img {

				position: relative;
				flex: $img-width;
				max-width: $img-width;

				display: grid;
				grid-auto-rows: 2;

				& > div {
					position: relative;
					width: 100%;
					overflow: hidden;
				}

				.badge {

					padding: 10px 15px;
					background: $red;
					border-bottom: 2px solid #000;
					@include font_avenger;
					text-align: center;
					color: #FFF;

				}

				.picture {
					
					&:before {
						content: '';
						display: block;
						width: 100%;
						height: 0;
						padding-top: 100%;
					}

					img {
						position: absolute;
						left: 0;
						// top: 0;
						bottom: 0;
					}
				}

				img {
					display: block;
					width: 100%;
					height: 100%;
					object-fit: cover;
				}

			}

			&.user .title {
				color: $red;
			}

			&.player.best-pick {

				div.img .picture {
					background-color: $green;
				}

			}

			&.player.hell-pick {

				.title {
					color: $orange;
				}

				div.img .picture {
					background-color: $orange;
				}

			}

		}

		& > div.team-ranks {

			flex-flow: row wrap;

			& > div {
				flex: 100%;
				max-width: 100%;
			}

			.title {
				padding: 10px 15px;
				margin-bottom: 0;
				border-bottom: 2px solid #000;
				background: $red;
				color: #FFF;
			}

			table {
				width: 100%;
				border-collapse: collapse;
			}

			tr > td {
				vertical-align: middle;
				padding: 15px 5px;
				border-bottom: 2px solid #000;
				line-height: 1em;
			}

			tr:last-child td {
				border-bottom: 0;
			}

			tr > td:nth-child(1),
			tr > td:nth-child(2) {

				padding: 16px 5px 14px;
				@include font_avenger;
				font-size: 1.1em;
				line-height: 1em;

				span {
					@include font_montserrat;
				}

			}

			tr > td:nth-child(1) {
				text-align: center;
			}

			tr > td:nth-child(2) {
				color: #000;
			}

		}

	}

	/** ---------------------------------------*\
		Barres graphiques
	*---------------------------------------- **/

	.group .container::v-deep .chart-bar,
	.group .container::v-deep .datas-table .chart-bar {

		&.last-total {

			color: lighten( #000, 75% );

			.chart-bar_bg {
				background-color: lighten( #000, 75% );
			}

		}

		&.total.rungood {

			color: $green;
			
			.chart-bar_bg {
				background-color: $green;
			}

		}

		&.total.notrungood {

			color: $red;
			
			.chart-bar_bg {
				background-color: $red;
			}

		}

		&.estimation {
			
			color: $orange;

			.chart-bar_bg {
				background-color: $orange;
			}

		}

	}

	/** ---------------------------------------*\
		Recap global
	*---------------------------------------- **/

	.group .container::v-deep #deck-recap_global {

		display: flex;
		flex-flow: row wrap;
		align-content: center;
		height: 130px;
		border-bottom: 2px solid #000;
		padding: 25px;
		font-size: 1em;
		line-height: 1.5em;
		font-weight: bold;

		.pts-total {
			width: 100%;
			margin-bottom: 25px;
			text-align: center;
			@include font_avenger;
			font-size: 1.4em;
		}

		.chart-bar > div {

			display: flex;
			flex-flow: row nowrap;
			justify-content: flex-start;
			align-content: center;
			width: 100%;
			max-width: 100%;
			margin: 0 auto;

			& > div {
				flex: auto;
			}

			& > div.chart-bar_label {
				flex: auto;
				max-width: 20%;
				padding-right: 15px;
				text-align: right;
			}

			& > div.bar {
				display: flex;
				max-width: 100%;
				justify-content: flex-start;
			}

			.chart-bar_value {

				max-width: 27%;
				padding-left: 15px;
				text-align: left;
				font-size: 0.875em;
			}

		}

		.chart-bar .chart-bar_bg {
			height: 25px;
		}

		.cart-bar {

			&.last-total {

				color: lighten( #000, 75% );

				.chart-bar_bg {
					background-color: lighten( #000, 75% );
				}

			}

			&.total.rungood {

				color: $green;
				
				.chart-bar_bg {
					background-color: $green;
				}

			}

			&.total.notrungood {

				color: $red;
				
				.chart-bar_bg {
					background-color: $red;
				}

			}

			&.estimation {
				
				color: $orange;

				.chart-bar_bg {
					background-color: $orange;
				}

			}

		}

	}

	/** ---------------------------------------*\
	*---------------------------------------- **/

	.group .container::v-deep .datas-table {

		height: calc( 100% - 130px );

		td.rank > div {

			@include font_avenger;
			line-height: 1em;
			font-size: 1.2em;

			em {
				@include font_montserrat;
				line-height: 1em;
				font-style: normal;
			}

			em.evo {
				margin-left: 5px;
				font-size: 0.8em;
			}

		}

	}
	
	/** ---------------------------------------*\
	*---------------------------------------- **/

	.group .container::v-deep .datas-table .scores > div {

		flex-flow: row wrap;
		align-content: center;
		padding-top: 10px;
		padding-bottom: 10px;

	}

	/** ---------------------------------------*\
	*---------------------------------------- **/

	.group .container::v-deep .datas-table .chart-bar {

		& > div {

			display: flex;
			flex-flow: row wrap;
			
			& > div {
				flex: 100%;
			}

		}

		&.total > div {
			flex-direction: column-reverse;
		}

		.chart-bar_label {
			line-height: 1.5em;
		}

	}



	/** ---------------------------------------*\
		
		Screenshot ON
	*---------------------------------------- **/

	.group.screenshot .container::v-deep {

		& > div {
			border: none;
			background: none;
		}

		.container-body {

			@include flex_display;
			// align-items: flex-start;
			align-items: stretch;
			justify-content: space-between;
		}

		.deck-recap_left {

			flex: $left_width;
			max-width: $left_width;
			border: 4px solid #000;
			background: #FFF;

		}

		.deck-recap_highlights {
			flex: calc( 100% - #{$left_width} - 5% );
			max-width: calc( 100% - #{$left_width} - 5% );
		}

	}

	/** ---------------------------------------*\
		Bloc droite
		Highlights

		Screenshot ON
	*---------------------------------------- **/

	.group.screenshot .container::v-deep .deck-recap_highlights {

		margin-top: 0;
		border: 0;
		background: transparent;

		& > div {

			$img-width	: 175px;
			
			border: 0;
			margin-bottom: 50px;

			div.datas-container {

				border: 0;
				font-size: 1em;

				div.title {

					padding-left: 0;
					font-size: 1.2em;

				}

				div.datas {

					background: #FFF;
					border: 2px solid #000;
					box-shadow: 10px 10px 0px lighten( $red, 5% );

					& > div:first-child {
						border-top: 0;
					}

					& > div.score {
						font-size: 0.8em;
					}

				}

			}

			div.img {

				position: relative;
				flex: $img-width;
				max-width: $img-width;
				height: $img-width;
				padding-top: 25px;
				padding-left: 25px;

				margin-top: 0;

				& > div {

					position: relative;
					width: 100%;
					height: 100%;
					overflow: hidden;
					background-color: lighten( $red, 10% );
					border: 2px solid #000;
					background-position: center bottom;
					background-size: cover;

				}

				.badge {

					position: absolute;
					width: 100px;
					height: auto;
					padding: 0;
					left: 15px;
					top: 25px;
					z-index: 2;
					transform: rotate(-25deg) translateX(-25px);
					transform-origin: left top;
					background: transparent;
					border: 0;
					color: #FFF;
					text-shadow: 3px 3px 2px darken( $red, 10% );
					font-size: 18px;

				}

				img {
					width: 120%;
					left: -10%;
					height: auto;
				}

			}

			&.user .title {
				color: #FFF;
			}

		}

		div.team-ranks {

			margin-bottom: 25px;

			.title {

				padding-left: 0;
				border: 0;
				font-size: 1.5em;
				color: #000;

			}

			tr > td {

				color: #FFF;
				font-weight: bold;
				text-shadow: none;

				&:first-child {
					padding-left: 10px;
					text-align: left;
				}

			}

		}

	}

</style>